.custom-form {
  width: 100%;

  input[type="text"],
  input[type="email"],
  select {
    width: 100%;
    height: 42px;
    border-radius: 3px;
    padding: 10px 15px;
    font-size: 12px;
    -webkit-appearance: none;
    border:0;
    margin-bottom: 5px;
    background-color: $alternateLight;
    color: $darker;
    font-family: $fontText !important;

    @media (min-width: $md-break) {
      height: 50px;
      font-size: 14px;
    }

    &::placeholder {
      color: $darker;
    }
  }

  textarea {
    width: 100%;
    max-width: 100%;
    height: 75px;
    max-height: 150px;
    border-radius: 3px;
    padding: 10px 15px;
    font-size: 12px;
    -webkit-appearance: none;
    border:0;
    margin-bottom: 5px;
    background-color: $alternateLight;
    color: $darker;
    font-family: $fontText !important;

    @media (min-width: $md-break) {
      height: 120px;
      font-size: 14px;
    }

    &::placeholder {
      color: $darker;
    }
  }

  select {
    background-image: url(/img/arrow-down.svg);
    background-position: calc(100% - 15px) center;
    background-repeat: no-repeat;
  }

  input[type="submit"],
  .site-btn {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    font-family: $fontText !important;
    background-color: $colorAccent2;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;

    &:hover {
      background: #fff;
      color: $colorAccent1;
    }
  }
}

.helper {
  display: flex;
  align-items: center;

  font-size: 12px;

  margin: 0;
  padding: 0;
  color: $darker;

  line-height: 1;

  @media (min-width: $md-break) {
    font-size: 14px;
  }

  .star {
    color: $colorAccent1;

    line-height: 1;
  }
}

.upload-zone {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  flex-wrap: wrap;

  width: 100%;

  .upload-item {
    position: relative;
    width: 100%;
    text-align: left;
    color: $darker;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 10px;

    cursor: pointer;

    background-image: url('/img/icon-pj.svg');
    background-color: $alternateLight;
    background-repeat: no-repeat;
    background-position: right 20px center;

    @media (min-width: 699px) {
      width: 100%;
      padding: 20px;
    }

    .upload-image {
      position: relative;
      cursor: pointer;
      transition: opacity 0.35s ease-in-out;

      &:hover {
        opacity: 0.55;
      }

      label.is-active,
      .filename.is-active {
        color: $darker;
      }

      &.is-active {
        color: $darker;
      }
    }

    img {
      display: block;
      margin: 0 auto;
      margin-bottom: 10px;
    }

    input[type="file"] {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
    }

    label {
      color: $darker;
      font-size: 12px;

      @media (min-width: 699px) {
        font-size: 16px;
      }
    }

    input {
      background-color: $alternateLight;
      margin: 0 auto;
      text-align: center;
    }

    .separator {
      position: relative;

      span {
        position: relative;
        z-index: 2;
        display: inline-block;
        padding: 5px;
        background-color: $grey;
      }

      &:before {
        position: absolute;
        top: 50%;
        left: 0;
        content: '';
        height: 2px;
        width: 100%;
        background-color: #fff;
      }
    }
  }
}

.form-notif {
  text-align: center;
  line-height: 1.4;
  background: $colorAccent1;
  border-top: 1px solid rgba($white, 0.25);
  color: $white;
  margin: 0;
  padding: 20px;
  font-size: 14px;
}
