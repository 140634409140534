/**
 * components/typography.css
 *
 * Typography rules.
 *
 */

html {
  font-family: $fontText;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $dark;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  color: $dark;
}

h1, h2, h3, h4, h5, h6 {
  color: $dark;
}

.big-title {
  font-family: $fontTitle;
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 1.2;
  text-transform: uppercase;

  @media (max-width: 919px) {
    font-size: 24px;
  }
}

.medium-title {
  font-family: $fontTitle;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;

  @media (max-width: 919px) {
    font-size: 16px;
  }
}

.small-title {
  font-family: $fontTitle;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  text-transform: uppercase;

  @media (max-width: 919px) {
    font-size: 14px;
  }
}

.regular-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;

  @media (max-width: 919px) {
    font-size: 14px;
  }
}

.small-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
}
