/**
 * pages/homepage.pcss
 *
 * Styles for the Home page.
 *
*/

/*! Global
---------------------------------------------- */
img {
  opacity: 0;
  transition: opacity 0.45s ease 0.45s;

  &.lazyloaded,
  &.flickity-lazyloaded {
    opacity: 1;
  }
}

.main-carousel .flickity-slider {
  img {
    opacity: 1;
  }
}

/*! Page Header and Navigation
---------------------------------------------- */
.site-header {
  background: $grey;
  color: $white;

  .site-header-top {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1rem 1.65rem;

    @media (max-width: 714px) {
      padding: 1rem 20px;
    }

    .header-license {
      min-width: 200px;
      font-size: 14px;
      color: $white;

      img {
        display: block;
        width: 100%;
        max-width: 80px;
        margin: 0 auto;
        position: relative;
        left: -25px;
      }

      .text {
        dominant-baseline:
      }

      strong {
        font-weight: 900;
      }

      @media (max-width: 714px) {
        display: none;
        order: 2;
        font-size: 14px;
      }
    }

    .header-logo-wrapper {
      margin: 0 auto;
      min-width: 80px;

      @media (max-width: 714px) {
        width: 100%;
        order: 1;
      }

      img {
        display: block;
        width: auto;
        height: 100%;
        max-height: 81px;

        @media (max-width: 919px) {
          max-height: 65px;
        }

        @media (max-width: 714px) {
          max-height: 45px;
          margin: 0;
        }
      }
    }

    .header-links {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-width: 200px;

      @media (max-width: 714px) {
        order: 2;
      }

      a,
      svg {
        transition: all 0.45s ease 0s;
      }

      a:hover {
        color: $colorAccent1;
      }

      svg {
        width: 20px;
        margin-left: 10px;
        fill: $white;

        &:hover {
          fill: $colorAccent1;
        }
      }
    }

    .link-phone {
      font-size: 16px;
      font-weight: 900;

      @media (max-width: 714px) {
        display: none;
        font-size: 14px;
      }
    }

    .link-phone-mobile {
      display: none;
      @media (max-width: 714px) {
        display: inline-block;
      }
    }
  }

  .site-header-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $dark;

    &.fixed {
      position: fixed;
      z-index: 99;

      top: 0;
      width: 100%;
    }

    @media (max-width: 714px) {
      display: none;
    }

    .nav-lang {
      display: inline-block;
      min-width: 210px;
      font-size: 14px;
      font-weight: 700;
      padding: 0 1.65rem;
      text-transform: uppercase;

      transition: color 0.45s ease 0s;

      &:hover {
        color: $colorAccent1;
      }

      @media (max-width: 919px) {
        font-size: 12px;
        min-width: inherit;
      }
    }

    .nav-cta {
      border-radius: 0;
    }

    .nav-links {
      display: flex;
      align-items: center;
      justify-content: space-between;

      list-style-type: none;
      margin: 0 auto;
      padding: 0;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;

      @media (max-width: 919px) {
        font-size: 12px;
      }

      @media (max-width: 714px) {
        display: none;
      }

      li {
        margin: 0;
        padding: 0;

        a {
          padding: 0 20px;
          transition: color 0.45s ease 0s;

          @media (max-width: 919px) {
            padding: 0 10px;
          }

          &:hover {
            color: $colorAccent1;
          }
        }
      }
    }
  }

  .mobile-only {
    display: none;

    @media (max-width: 714px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: $dark;
      padding: 0.5rem 20px;

      &.fixed {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 99;
      }
    }
  }
}

/*! Site Hero
---------------------------------------------- */
.site-hero {
  position: relative;
  overflow: hidden;

  background: $grey;
  color: $white;

  .site-hero-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 714px) {
      flex-direction: column;
    }
  }

  .site-hero-content {
    position: relative;
    width: 50%;
    z-index: 2;

    background: rgba($dark, 0.6);
    color: $white;
    padding: 12vw 7vw;
    padding-right: 5vw;

    @media (max-width: 919px) {
      width: 60%;
    }

    @media (max-width: 714px) {
      width: 100%;
    }

    .hero-title {
      width: 100%;
      max-width: 540px;
      margin: 0;
      padding: 30px 0;
      color: $white;
    }

    .hero-subtitle {
      margin: 0;
      padding: 0;
      color: $white;
    }
  }

  .site-hero-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/*! Site Footer
---------------------------------------------- */
.site-footer {
  .copy {
    padding: 1rem;
    text-align: center;
    color: $dark;

    @media (max-width: 919px) {
      padding: 0;
    }
  }
}

/*! Block Thumbs Grid
---------------------------------------------- */
.block-thumbs-grid {
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;

  @media (max-width: 919px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .thumbs-grid-text {
    width: 100%;
    max-width: 605px;
    margin: 0 auto;
    padding-bottom: 75px;

    @media (max-width: 919px) {
      padding-bottom: 45px;
    }
  }

  .thumbs-grid {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 714px) {
      flex-wrap: nowrap;
      overflow-y: hidden;
      overflow-x: auto;
    }

    .thumb-item {
      position: relative;
      width: calc(99% / 3);
      margin-bottom: 6px;
      overflow: hidden;
      transition: all 0.45s ease 0s;

      /*
      &:hover {
        img {
          transform: scale(1.15);
        }

        .thumb-content {
          background: rgba($white, 0.8);
          color: $dark;
        }
      }
      */

      @media (max-width: 714px) {
        min-width: 78vw;
        margin-right: 5px;
      }

      img {
        display: block;
        width: 100%;
        transition: all 0.45s ease 0s;
      }

      .thumb-content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($dark, 0.8);
        color: $white;
        text-align: left;

        margin: 0;
        padding: 15px 25px;

        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        font-size: 16px;
        font-weight: 700;
        line-height: 1.2;

        transition: all 0.45s ease 0s;
      }
    }

    &.is-5 {
      .thumb-item {
        &:nth-child(4),
        &:nth-child(5) {
          width: calc(99.5% / 2);
          max-height: 275px;

          overflow: hidden;

          @media (max-width: 714px) {
            width: 100%;
          }

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

/*! Block Slider
---------------------------------------------- */
.block-slider {
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;

  @media (max-width: 919px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &.is-testimonial {
    padding-bottom: 10rem;

    @media (max-width: 919px) {
      padding-bottom: 3rem;
    }
  }

  .slider-title {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 25px;

    @media (max-width: 919px) {
      padding-bottom: 45px;
    }
  }

  .slider-text {
    width: 100%;
    max-width: 805px;
    margin: 0 auto;
    padding-bottom: 45px;
  }

  .slider-cta {
    margin-bottom: 75px;
  }

  .slider-wrapper {
    width: 100%;

    .slider-item {
      width: 100%;

      img {
        display: block;
        width: 100%;

        @media (max-width: 714px) {
          min-height: 325px;
          object-fit: cover;
        }
      }
    }
  }

  &.is-testimonial {
    .slider-item {
      padding: 8vw 10vw;
      background: $grey;

      svg {
        max-width: 80px;
        margin: 0 auto;

        @media (max-width: 714px) {
          max-width: 55px;
        }
      }
    }

    .testimonial-text {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      padding: 15px 0;
      font-size: 18px;
      font-weight: 800;
      line-height: 1.5;
      text-align: center;
      color: $white;

      @media (max-width: 714px) {
        font-size: 16px;
        line-height: 1.2;
        font-weight: 400;
      }
    }

    .testimonial-subtext {
      color: $white;
      line-height: 1.5;
    }
  }
}

/*! Block Split
---------------------------------------------- */
.block-split {
  padding-top: 5rem;
  padding-bottom: 5rem;
  color: $dark;

  @media (max-width: 919px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &.is-dark {
    background: $dark;
    color: $white;

    .split-title {
      color: $white;
    }

    .split-text {
      color: $white;
    }
  }

  .block-split-container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @media (max-width: 714px) {
      flex-direction: column;
    }
  }

  .split-image {
    width: 45%;

    @media (max-width: 714px) {
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: 714px) {
        max-height: 225px;
        object-fit: cover;
      }
    }
  }

  .split-content {
    width: 55%;
    padding: 5vw;

    @media (max-width: 714px) {
      width: 100%;
      padding: 0;
    }
  }

  .split-list {
    margin: 0;
    padding: 0;
    padding-bottom: 25px;

    li {
      margin: 0;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row-reverse;
    }

    svg {
      background: $white;
      border-radius: 50%;
      stroke: $colorAccent1;
      padding: 2px;
      margin-right: 10px;
    }
  }
}

/*! Block Icons Grid
---------------------------------------------- */
.block-icons-grid {
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;

  @media (max-width: 919px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .icons-grid-title {
    padding-bottom: 25px;
  }

  .icons-grid {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 714px) {
      flex-direction: column;
    }

    .icon-item {
      position: relative;
      text-align: center;
      width: calc(95% / 3);

      @media (max-width: 714px) {
        width: 100%;
        padding-bottom: 25px;
        max-width: 300px;
        margin: 0 auto;
      }

      img {
        display: block;
        width: 100%;
        max-height: 100px;
        margin: 0 auto;
      }

      .icon-title {
        width: 100%;
        max-width: 225px;
        margin: 0 auto;
        padding: 20px 0;
      }

      .icon-text {
        margin: 0;
        padding: 0;
      }
    }
  }
}

/*! Block Instagram
---------------------------------------------- */
.block-instagram {
  padding-top: 5rem;
  padding-bottom: 0;

  @media (max-width: 919px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .instagram-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      transition: all 0.45s ease 0s;
      color: $dark;

      &:hover {
        color: $colorAccent1;
      }
    }

    svg {
      width: 25px;
      fill: $dark;
      margin-left: 15px;

      transition: all 0.45s ease 0s;

      &:hover {
        fill: $colorAccent1;
      }
    }
  }

  .instagram-grid {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;

    .instagram-item {
      position: relative;
      overflow: hidden;
      width: calc(99% / 4);
      max-height: 245px;
      margin-bottom: 4px;
      cursor: pointer;

      @media (max-width: 714px) {
        width: calc(99% / 2);

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          display: none;
        }
      }

      &:hover {
        img {
          transform: scale(1.15);
        }
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        transition: all 0.45s ease 0s;
        object-fit: cover;
      }
    }
  }
}

/*! Block Image
---------------------------------------------- */
.block-image {
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-right: 0;
  padding-left: 0;
  text-align: center;

  @media (max-width: 919px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .image-title {
    padding-left: 20px;
    padding-right: 20px;
  }

  .image-text {
    width: 100%;
    max-width: 605px;
    margin: 0 auto;
    padding-bottom: 75px;
    padding-left: 20px;
    padding-right: 20px;

    @media (max-width: 919px) {
      padding-bottom: 45px;
    }
  }

  figure {
    width: 100%;
    margin: 0;
    padding: 0;
    img {
      display: block;
      width: 100%;
    }
  }
}

/*! Notification block
---------------------------------------------- */
.notification {
  background: $dark;
}

.notification-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 714px) {
    flex-direction: column;
  }

  .notification-title {
    padding: 2vw;
    color: $colorAccent1;
    font-size: 30px;
    font-weight: 800;
    white-space: nowrap;
    text-align: left;
    margin: 0;
    padding-left: 0;

    @media (max-width: 714px) {
      font-size: 23px;
      padding: 20px;
      padding-bottom: 0;
    }
  }

  .notification-text {
    padding: 2vw 2vw 2vw 4vw;
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    color: $white;

    @media (max-width: 714px) {
      padding: 20px;
      font-size: 14px;
    }
  }
}

/*! Aside form
---------------------------------------------- */
.aside-form-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  background: rgba($darker, 0.70);

  opacity: 0;
}

.aside-form {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 85%;
  max-width: 500px;
  z-index: 999;

  background: #fff;

  transform: translate(100%, 0);

  transition: all 0.45s ease;

  .aside-form-header {
    position: relative;
    padding: 20px 20px;

    background: $white;
    color: $dark;

    @media (min-width: $md-break) {
      padding: 20px 35px;
    }

    svg {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(0, -50%);

      width: 15px;

      cursor: pointer;

      fill: $dark;

      @media (min-width: $md-break) {
        right: 35px;
      }
    }
  }

  .aside-form-title {
    text-align: center;
    font-size: 22px;
  }

  .aside-form-content {
    padding: 20px 20px;
    height: 100%;
    overflow-y: scroll;
    padding-top: 0;

    display: none;

    &.is-active {
      display: block;
    }

    @media (min-width: $md-break) {
      padding: 45px 35px;
      padding-top: 0;
    }

    .product-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;

      margin: 0;
      padding: 0;
      padding-bottom: 25px;

      img {
        display: block;
        width: 65px;
        margin-right: 10px;
        border-radius: 60px;
      }

      .small-title {
        font-size: 14px;
      }
    }

    .small-title,
    .small-text {
      margin: 0;
      padding: 0;

      &.subtitle {
        text-transform: none;
        font-weight: 400;
        color: $grey;
        padding: 3px 0;
      }

      a {
        color: $colorAccent1;
      }
    }
  }
}

.aside-form-active {
  .aside-form-overlay {
    opacity: 1;
    z-index: 9;
  }

  .aside-form {
    transform: none;
  }
}

.navigation-mobile {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100000000;
  transform: translateX(400px);
  transition: all 0.3s ease-in-out;
  background-color: $white;

  @media all and (max-width: 1140px) {
    width: 400px;
    height: 100vh;
  }

  @media all and (max-width: 499px) {
    width: 85%;
  }

  &.is-active {
    @media all and (max-width: 1140px) {
      display: block;
      transform: translateX(0px);
    }
  }

  &.active {
    @media all and (max-width: 1140px) {
      width: 400px;
      display: flex;
    }

    @media all and (max-width: 499px) {
      width: 85%;
    }
  }

  .navigation-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: $grey;

    .logo {
      height: 60px;

      img {
        display: block;
        width: auto;
        height: 60px;
      }
    }
  }

  .navigation-close {
    flex-grow: 1;
    text-align: right;
    font-size: 28px;
    color: $white;
  }

  .navigation-link {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-top: 1px solid rgba(#000, 0.075);

    a {
      margin-bottom: 10px;
      color: $dark;
      font-size: 16px;
      text-align: center;
      line-height: 1;
      background-color: $light;
      padding: 15px;
      border-radius:3px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .header-license-img {
    display: block;
    width: 100%;
    max-width: 80px;
    margin: 25px auto 0 auto;
  }

  .navigation-sublink {
    display: flex;
    flex-direction: column;
    padding: 0 20px 15px;
    border-bottom: 1px solid rgba(#000, 0.075);
    text-align: center;

    a {
      font-size: 14px;
      color: $dark;
      text-transform: uppercase;
      text-align: center;

      &.site-btn {
        color: $white;

        &:hover {
          color: $dark;
        }
      }
    }
  }

  .navigation-footer {
    display: flex;
    align-items: center;
    padding: 20px 0;

    a {
      font-size: 13px;
      padding-left: 20px;

      i {
        position: relative;
        top: 1px;
        font-size: 16px;
      }
    }
  }

  .navigation-footer-social {
    flex-grow: 1;

    a {
      padding: 0;
    }

    svg {
      margin-right: 20px;
      height: 24px;
      transition: all .3s ease-in-out;
    }
  }

  .subnav-mobile {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-bottom: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;

    &.active {
      max-height: inherit;
      opacity: 1;
      padding-bottom: 20px;
    }

    a {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}

.mobile-menu-trigger {
  display: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: url('/img/icon-burger.svg') no-repeat center center / contain;

  @media (max-width: 714px) {
    display: block;
  }
}

.navigation-mobile-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  background: rgba($darker, 0.70);

  opacity: 0;

  &.is-active {
    opacity: 1;
    z-index: 15;
  }
}
