/*! Site Buttons
---------------------------------------------- */
.site-btn {
  display: inline-block;
  padding: 1.45rem 1.65rem 1.35rem 1.65rem;
  border: 2px solid $colorAccent1;
  text-decoration: none;
  background: $colorAccent1;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: $white;
  text-transform: uppercase;
  transition: all 0.6s ease;
  cursor: pointer;

  &:hover {
    border-color: rgba($dark, 0.6);
    background: $white;
    color: $colorAccent1;
  }

  @media (max-width: 919px) {
    border-width: 1px;
    font-size: 14px;
    padding: 1.15rem 1.135rem 1.05rem 1.135rem;
  }
}
